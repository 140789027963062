import { Heading, Flex, Text, Container, Divider, Image } from '@chakra-ui/react'
import { Carousel } from '@miimosa/design-system'
import { Testimony } from '@miitypes'
import { s3ImageURL } from '@miimosa/common'

const renderTestimonial = ({ message, name }: Testimony, index: number) => (
  <Flex key={index} position="relative" direction="column" px="16px" justify="end" align="end">
    <Image zIndex={1} src={s3ImageURL('quotes.svg')} width="100px" height="100px" position="absolute" top="0" />

    <Flex direction="row">
      <Divider orientation="vertical" mr={4} border="solid 1px" h="200px" borderColor="brand_orange" mt={10} />
      <Flex direction="column" justifyContent="space-between">
        <Text size="md" color="black" zIndex={2} mt={10}>
          {message}
        </Text>
        <Text> {name} </Text>
      </Flex>
    </Flex>
  </Flex>
)

export const Testimonials = ({ testimonies }: { testimonies: any }) => {
  return (
    <Container position="relative" alignItems="center" py={0} mb={8}>
      <Flex direction={{ base: 'column', md: 'row' }} alignItems="center" justifyContent="center" w={'100%'}>
        <Heading textAlign="center" color="brand_green" mr={4}>
          Témoignage
        </Heading>

        <Carousel
          isDark={false}
          draggable
          boxedControls
          SlideComponent={(testimony: Testimony, index: number) => renderTestimonial(testimony, index)}
          itemsPerPage={1}
          hasDots={false}
          data={testimonies}
          navColor="brand"
          navBottom
        />
      </Flex>
    </Container>
  )
}

export default Testimonials
