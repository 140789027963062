import { Text } from '@chakra-ui/react'

export const SolsVivantsItems = [
  {
    title: 'La réduction du travail des sols',
    bgColor: 'brand_light_green',
    textColor: 'black',
    text: (
      <>
        <Text>
          Notamment le labour. Bien que parfois nécessaire, le travail du sol intensif perturbe la vie du sol (ver de
          terre, micro-organisme, etc.). Ne pas travailler le sol permet d’avoir notamment une bonne infiltration de
          l’eau.
        </Text>
      </>
    ),
  },
  {
    title: 'La diversification des cultures',
    bgColor: 'brand_yellow',
    textColor: 'black',
    text: (
      <>
        <Text>
          La diversification des cultures vise à cultiver un maximum d’espèces différentes en alternance dans les
          champs. Cela permet notamment de lutter contre les maladies.
        </Text>
      </>
    ),
  },
  {
    title: 'Les couverts végétaux',
    bgColor: 'brand_orange',
    textColor: 'black',
    text: (
      <>
        <Text>
          La mise en place de couverts végétaux entre les cultures de rente permet de ne pas laisser les sols à nu et
          produire de la matière organique pour les alimenter. Cela vise à améliorer la fertilité des sols.
        </Text>
      </>
    ),
  },
  {
    title: 'L’agroforesterie',
    bgColor: 'brand_brown',
    textColor: 'black',
    text: (
      <>
        <Text>
          L’agroforesterie consiste à remettre l’arbre au cœur du système agricole. Celui-ci ayant de nombreux avantages
          agronomiques et écologiques : bien-être animal, refuge pour la biodiversité, fertilité des sols.
        </Text>
      </>
    ),
  },
]
