import {
  Link,
  Grid,
  Heading,
  Flex,
  Text,
  useDisclosure,
  Button,
  AspectRatio,
  Box,
  Image,
  Tooltip,
} from '@chakra-ui/react'
import { Icon } from '@miimosa/design-system'

import { Form } from '@components/common'
import {
  getValidVideoId,
  s3ImageURL,
  vimeoBaseUrl,
  vimeoVideoRegex,
  youtubeBaseUrl,
  youtubeIdVideoRegex,
  youtubeVideoRegex,
} from '@miimosa/common'
import React, { useState } from 'react'
import { FaPlay } from 'react-icons/fa'
import { Poll } from '@miitypes'

const ProjectImageOrVideo = ({ poll }: { poll: Poll }) => {
  const isYoutubeVideo = youtubeVideoRegex.test(poll.video_url || '')

  const validVideoId = getValidVideoId({
    videoUrl: poll.video_url || '',
    regexp: isYoutubeVideo ? youtubeIdVideoRegex : vimeoVideoRegex,
  })

  const youtubeVideo = `${youtubeBaseUrl}${validVideoId}?autoplay=1`
  const vimeoVideo = `${vimeoBaseUrl}${validVideoId}?autoplay=1`

  const [videoIsPlaying, setVideoIsPlaying] = useState(false)

  return (
    <>
      {poll.video_url && validVideoId && videoIsPlaying && (
        <AspectRatio ratio={4 / 3} w="100%">
          <Box
            as="iframe"
            style={{ borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}
            src={isYoutubeVideo ? youtubeVideo : vimeoVideo}
            allowFullScreen
            zIndex={2}
            w="100%"
            allow="autoplay"
          />
        </AspectRatio>
      )}
      {poll.image_url && !videoIsPlaying && (
        <Flex alignItems="center" justifyContent="center" direction="column" w="full">
          <Box pos={'relative'}>
            <Image
              style={{ objectFit: 'cover', borderTopLeftRadius: '25px', borderTopRightRadius: '25px' }}
              aspectRatio={4 / 3}
              w="100%"
              maxW={{ base: '800px', lg: '100%' }}
              alt=""
              src={s3ImageURL(poll.image_url)}
            />
            <Box
              pos="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              bg="linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%)"
              zIndex="1"
              borderTopLeftRadius="25px"
              borderTopRightRadius="25px"
            />
            <Flex direction="column" pos="absolute" bottom="5%" color="white" zIndex="2" mx={4} w={'90%'}>
              <Box fontWeight="bold" mb={1} fontSize={18} w="90%">
                {poll.title}
              </Box>
              <Flex direction="column" justifyContent="space-between" color="brand_green_text" fontWeight={400}>
                <Flex whiteSpace="nowrap" fontSize={16} alignItems="center">
                  <Icon name="Pin" h={{ base: '14px', md: '18px' }} w="auto" mr={1} />
                  <Text>{poll.city}</Text>
                </Flex>
                <Flex whiteSpace="nowrap" fontSize={16} alignItems="center" fontWeight={400}>
                  <Icon name="User" h={{ base: '14px', md: '16px' }} w="auto" mr={1} />
                  <Text>{poll.owner_full_name}</Text>
                </Flex>
              </Flex>
            </Flex>
          </Box>
          {poll.video_url && validVideoId && (
            <Flex
              w="50px"
              h="50px"
              borderRadius="full"
              backgroundColor="white"
              opacity={0.8}
              zIndex={2}
              alignItems="center"
              justifyContent="center"
              _hover={{ cursor: 'pointer' }}
              onClick={() => setVideoIsPlaying(true)}
              position="absolute"
            >
              <FaPlay color="black" />
            </Flex>
          )}
        </Flex>
      )}
    </>
  )
}

const ProjectCard = ({ poll }: { poll: Poll }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const linksEnabled = false
  return (
    <Flex direction="column" order={poll.order} minW="350px">
      <Box as="span" id={poll.anchor} visibility="hidden" height="0" transform="translateY(-80px)" />

      <Flex direction="column">
        <ProjectImageOrVideo poll={poll} />
        <Flex direction="column" bgColor="brand_light_yellow" px={6} py={4} borderBottomRadius="25px">
          <Flex direction="column" h={{ base: 'auto', md: '250px' }}>
            <Text fontSize="16" mb={4} dangerouslySetInnerHTML={{ __html: poll.text }} />
            <Tooltip label="Bientôt disponible" isDisabled={linksEnabled} placement="right-start">
              <Link
                w="fit-content"
                mb={6}
                as={linksEnabled ? 'a' : 'span'}
                {...(linksEnabled ? { href: 'https://miimosa.com/' + poll.slug } : {})}
              >
                En savoir plus
              </Link>
            </Tooltip>
          </Flex>

          <Text fontSize="12" color="brand_green" mb={4}>
            <Text as="span" fontWeight="bold">
              {poll.total_vote_count} citoyens
            </Text>{' '}
            ont voté pour ce projet
          </Text>

          <Button onClick={onOpen} w="full">
            Je vote pour ce projet
          </Button>

          <Form
            isOpen={isOpen}
            slug={poll.slug}
            fullname={poll.owner_full_name}
            text={poll.text}
            onClose={onClose}
            onVoted={() => undefined}
            platform="NESTLE_PURINA"
            borderRadius="25px"
            projectLink={true}
            logo={
              <Image src={s3ImageURL('purina-logo-friskies.png')} h={{ base: '100px', md: '150px' }} w="auto" mb={4} />
            }
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export const SoutenezVotreProjet = ({ polls }: { polls: Poll[] }) => {
  return (
    <Flex position="relative" alignItems="center" justifyContent="center" bgColor="brand_yellow" w="full" mb={12}>
      <Flex direction="column" w="80%" alignItems="center" justifyContent="center">
        <Box as="span" id="agriculteurs" visibility="hidden" height="0" transform="translateY(-80px)" />

        <Heading textAlign="center" my={12}>
          Soutenez votre projet préféré
        </Heading>
        <Grid
          justifyContent="center"
          alignItems="center"
          templateColumns={{ base: 'none', md: 'repeat(3, 1fr)', lg: 'repeat(3, 1fr)' }}
          columnGap="30px"
          rowGap="50px"
          mb={8}
        >
          {polls.map((p) => (
            <ProjectCard poll={p} key={p.slug} />
          ))}
        </Grid>
      </Flex>
    </Flex>
  )
}
