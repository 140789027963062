import { Flex, Heading, Button, Text, Image, useBreakpointValue, Box } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'
import React from 'react'

const underlined = (text: string) => (
  <Text as="span" fontWeight="bold" fontSize="20px" bg="linear-gradient(0deg, #FFD462 50%, #F5F7ED 50%)">
    {text}
  </Text>
)

export const FriskiesSengage2 = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  return (
    <Flex width="full" bgColor="white" alignItems="center" direction="column" mb={12}>
      <Box as="span" id="demarche" visibility="hidden" height="0" transform="translateY(-80px)" />
      <Heading textAlign="center" marginBottom="40px">
        FRISKIES
        <sup style={{ fontSize: 16, verticalAlign: 'super' }}>®</sup>,{' '}
        {!isMobile ? 'une marque historique qui s’engage' : 'marque historique'}
      </Heading>

      <Flex
        width={{ base: 'full', md: '60%' }}
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
        mb={8}
        justifyContent="center"
      >
        <Image
          src={s3ImageURL('purina-sac-chat-pertu.png')}
          alt={''}
          w={{ base: '60%', md: '40%' }}
          mr={{ base: 0, md: -32 }}
          p={8}
        />
        <Flex direction="column" width={{ base: '90%', md: '60%' }} alignItems="center" mt={{ base: -12, md: 0 }}>
          <Flex zIndex="1" borderRadius="30px" mb={4}>
            <Flex bg="white" p={{ base: 2, md: 4 }} borderRadius="30px">
              <Flex bg="brand_very_light_green" borderRadius="30px" p={4} lineHeight={6}>
                <Text>
                  Avec plus de {underlined('50 ans')} de présence sur le territoire français, FRISKIES® va encore plus
                  loin dans son engagement.
                  <br />
                  <br />
                  En 2023, {underlined('95%')} des croquettes FRISKIES® sont fabriquées en France à l’usine de
                  Marconnelle. Anne-Sophie, Hervé et Aurélien font partie des agriculteurs du programme Sols Vivants qui
                  fournissent l’usine en blé.
                  <br />
                  <br />
                  En 2024, nous voulons aller plus loin dans notre démarche en soutenant financièrement les agriculteurs
                  dans leur projet d’investissement dans cette transition vers des méthodes d’agriculture plus durables.
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Button as="a" href="#agriculteurs">
        Je vote pour mon projet préféré
      </Button>
    </Flex>
  )
}
