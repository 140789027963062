import { Text } from '@chakra-ui/react'
import { MiimosaCestQuoi, FinancementParticipatif } from '@components/common'

export const questions = [
  {
    title: 'Comment fonctionne l’opération ?',
    bgColor: 'brand_light_green',
    textColor: 'black',
    text: (
      <>
        <Text>
          FRISKIES® s’engage à soutenir le projet de 3 agriculteurs pour les accompagner dans leur transition vers
          l’agriculture régénératrice dans la limite totale de 30 000€. 15 000 € seront répartis équitablement entre les
          3 agriculteurs soit 5 000€ chacun.
          <br />
          <br />
          Les 15 000€ restants seront répartis au prorata des votes en ligne.
          <br />
          <br />
          Les 3 agriculteurs font une collecte de financement participatif sur MiiMOSA pour faire découvrir leurs enjeux
          et permettre aux citoyens de contribuer à des projets plus vertueux et durables pour leur exploitation.
        </Text>
      </>
    ),
  },
  {
    title: 'FRISKIES® en quelques mots',
    bgColor: 'brand_yellow',
    textColor: 'black',
    text: (
      <>
        <Text>
          Avec plus de 50 ans d’expertise, FRISKIES® nourrit depuis des générations des chats et des chiens heureux et
          en bonne santé grâce à une alimentation savoureuse et 100% complète et équilibrée.
        </Text>
      </>
    ),
  },
  {
    ...MiimosaCestQuoi,
    bgColor: 'brand_orange',
    textColor: 'black',
  },
  {
    ...FinancementParticipatif,
    bgColor: 'brand_brown',
    textColor: 'black',
  },
]
