import { Button, Flex, Image, Heading, useBreakpointValue } from '@chakra-ui/react'
import { s3ImageURL } from '@miimosa/common'

export const Hero = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' })
  if (isMobile) {
    return (
      <Flex
        position="relative"
        h="300px"
        bgImage={s3ImageURL('purina-hero.jpeg')}
        bgSize="cover"
        bgPosition="0 60%"
        direction="column"
        alignItems="center"
        p={4}
        w={'full'}
      >
        <Image src={s3ImageURL('purina-logo-friskies.png')} w="100px" my={4} />

        <Heading color="white" textShadow="0px 4px 4px rgba(0, 0, 0, 0.5)" textAlign="center">
          FRISKIES<sup style={{ fontSize: 16, verticalAlign: 'super' }}>®</sup> s’engage pour l’agriculture
          régénératrice
        </Heading>

        <Button as="a" href="#agriculteurs">
          Je vote pour mon projet préféré
        </Button>
      </Flex>
    )
  } else {
    return (
      <Flex w="full" direction="column" mb={10}>
        <Flex position="relative" h="300px" bgImage={s3ImageURL('purina-hero.jpeg')} bgSize="cover" bgPosition="0 30%">
          <Flex
            w="full"
            h="full"
            zIndex={2}
            direction="row"
            justifyContent="space-between"
            position="absolute"
            alignItems="center"
          >
            <Flex maxWidth="800px" ml={32}>
              <Heading color="white" size={'xl'} textShadow="0px 4px 4px rgba(0, 0, 0, 0.5)">
                FRISKIES<sup style={{ fontSize: 16, verticalAlign: 'super' }}>®</sup> s’engage pour l’agriculture
                régénératrice
              </Heading>
            </Flex>
            <Flex direction={'column'} mr={32} alignItems="center">
              <Image src={s3ImageURL('purina-logo-friskies.png')} w="200px" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    )
  }
}
