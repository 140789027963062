export { Footer } from './Footer'
export { Hero } from './Hero'
export { FAQ } from './Faq'
export { questions } from './data/faqQuestions'
export { projects } from './data/projects'
export { CommentCaMarche } from './CommentCaMarche'
export { FriskiesSengage } from './FriskiesSengage'
export { FriskiesSengage2 } from './FriskiesSengage2'
export { LeProgrammeSolsVivants } from './LeProgrammeSolsVivants'
export { LesEngagementsPurina } from './LesEngagementsPurina'
export { SoutenezVotreProjet } from './SoutenezVotreProjet'
export { Testimonials } from './Testimonials'
