import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { s3ImageURL } from '@miimosa/common'

export const CommentCaMarche = () => {
  return (
    <>
      <Flex
        width={{ base: 'full', md: '80%' }}
        height="fit-content"
        bgColor="brand_white"
        borderRadius={{ base: 'none', md: '16px' }}
        p={12}
        alignItems="center"
        direction={{ base: 'column', md: 'row' }}
        mb={{ base: 0, md: 12 }}
        maxW="1000px"
        border="5px solid"
        borderColor="brand_yellow"
      >
        <Flex
          w="full"
          direction="column"
          mr={{ base: 0, md: 8 }}
          textAlign={{ base: 'center', md: 'left' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
        >
          <Box as="span" id="comment_ca_marche" visibility="hidden" height="0" transform="translateY(-80px)" />
          <Image src={s3ImageURL('purina-logo-friskies.png')} w="150px" mb={8} />

          <Text>
            FRISKIES® participe au financement de 3 projets d’agriculture régénératrice avec une enveloppe totale de
            30&nbsp;000€.
            <br />
            <br />
            Chaque projet agricole est assuré de recevoir 5&nbsp;000€.
            <br />
            Les 15&nbsp;000€ restants sont répartis au prorata des votes en ligne entre les trois projets.
            <br />
            <br />
            Votre voix compte,{' '}
            <Text as="span" fontWeight="bold">
              votez pour votre projet préféré !
            </Text>
          </Text>
        </Flex>
        <Flex direction="column" w={{ base: 'full', md: '50%' }} alignItems="center" rowGap={2} mt={8}>
          <Image src={s3ImageURL('purina-carte-updated.png')} alt={''} maxW="250px" />
        </Flex>
      </Flex>
    </>
  )
}
